import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css'
import Navbar from './components/Navbar'
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import Footer from './components/Footer';


export default function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
      <Footer date={'19 Dec 2024'}/>
    </div>

  );
}
