import './home_content.css'

import grassfieldLogo from '../images/grassfield_logo.png'
import purdueP from '../images/purdue_p.png'
import gitHub_logo from '../images/gitHub.png'
import react_logo from '../images/react.png'
import cdyne_logo from '../images/cdyne.png'
import ETS_logo from '../images/ETS.jpg'
import usSeal from '../images/usSeal.png'
import euro_logo from '../images/european_autowerks.png'
import zetier_logo from '../images/zetier.png'

import About_Json from './about.json'

const Purdue = <a href="https://engineering.purdue.edu/ECE" target="_blank" rel="noopener noreferrer">Purdue University</a>
const GitHub = <a href="https://github.com/g-hurst" target="_blank" rel="noopener noreferrer">GitHub</a>
const React = <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">ReactJS</a>
const STEM_academy = <a href="https://www.cpschools.com/o/gfh/page/governors-stem-academy" target="_blank" rel="noopener noreferrer">Governor’s STEM Academy</a>


const image_map = {
   'usSeal':usSeal,
   'grassfield_logo':grassfieldLogo,
   'purdue_p':purdueP,
   'gitHub':gitHub_logo,
   'react':react_logo,
   'cdyne':cdyne_logo,
   'ETS':ETS_logo,
   'european_autowerks':euro_logo,
   'zetier':zetier_logo,
}


// Beginning of the education content section
const purdue_ms_content = () => {
  return <div className="educationContent">
    <ul>
      <h4>Degree</h4>
      <ul>
        <li>Major: Computer Engineering</li>
        <li>GPA: 4.00/4.00</li>
        <li>Projected Graduation: May 2027</li>
      </ul>
      <div className="eduSubsections">
        <div className="eduSection">
          <h4>Relavant Coursework</h4>
          <ul>
            <li>CS 52700: Software Security</li>
            <li>ECE 50025: Machine Learning </li>
            <li>ECE 59595: Reinforcement Learning </li>
            <li>ECE 57000: Artificial Inteligence </li>
            <li>MA 53200: Elements Of Stochastic Processes</li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
}
const purdue_bs_content = () => {
  return <div className="educationContent">
    <ul>
      <h4>Degree</h4>
      <ul>
        <li>Major: Computer Engineering</li>
        <li>GPA: 3.63/4.00</li>
      </ul>
      <div className="eduSubsections">
        <div className="eduSection">
          <h4>Relavant Coursework</h4>
          <ul>
            <li>ECE 49595: Open Source Sofware (senior design)</li>
            <li>ECE 50863: Computer Network Systems</li>
            <li>ECE 49595: Game Theory</li>
            <li>ECE 46900: Operating Systems Engineering</li>            
            <li>ECE 36800: Data Structures</li>
            <li>ECE 30200: Probabilistic Methods in ECE</li>
            <li>ECE 30100: Signals and Systems</li>
            <li>ECE 26400: Advanced C Programming</li>
            <li>ECE 20875: Python for Data Science</li>
            <li>ECE 27000: Introduction to Digital System Design</li>
            <li>ENGR 16100/16200: Honors Introduction to Engineering Design</li>
          </ul>
        </div>
        <div className="eduSection">
          <h4>Activities & Experiences</h4>
          <ul>
          <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Defense Civilian Training Corps: </i> <br />
                (Fall 2023 - 2025)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Selected as a member of the first cohort of the Defense Civilian Training 
                Corps (DCTC), completing two years of comprehensive workforce readiness 
                training with the Department of Defense (DoD). Developed key skills in leadership, 
                collaboration, and resilience to ensure a seamless transition into civilian service. 
                Gained hands-on experience through an internship with the DoD, concluding with a 
                briefing to 250+ leaders in the DoD, academia, and industry. 
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Purdue Rifle Team: </i> <br />
                (Fall 2021 - 2025)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Member of the Purdue Rifle Team and trip safety leader on away competitions. 
                Competes monthly against other schools in both Olympic Smallbore and Air Rifle.
                Helps run Purdue Rifle and Pistol Club events. 
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Undergraduate Research Assistance: </i> <br />
                (Spring 2023)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Explored current neural network exploitation techniques and assisted in exploitation development. 
                Implemented trusted environment execution for AI/ML functions.
                Automated software verification process to run comprehensive tests and validate results.
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Purdue Electric Racing: </i> <br />
                (Fall 2021)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Developed embedded C software for microcontrollers that sends data to motor controllers.
                Defined functions to setup motor controllers, change modes, and control power to nearest 0.1%.
                Created documentation for microcontroller setup and function use.
              </div>
            </div>
            <div className='edu_purdue_acttivities_wrapper'>
              <div className='edu_purdue_acttivities_left'>
                <i>Honors College Leadership Retreat:</i> <br />
                (Fall 2021)
              </div>
              <div className='edu_purdue_acttivities_right'>
                Attended over 10 hours of leadership and teaming workshops. 
                Practiced abilities to effectively interact, instruct, and communicate with small and large groups.
                Practiced self-reflection, working collectively toward a greater vision, and commitment to inclusion and equity.
              </div>
            </div>
          </ul>
        </div>
      </div>
    </ul>
  </div>
}
const hs_content = () => {
  return <div className="">
    <ul>
      <li>4.867/4.0 GPA</li>
      <li>Graduated 10th in a class of 564 students</li>
      <li>Selected from over 500 students to be in the {STEM_academy} Engineering and Technology Pathway</li>
    </ul>
  </div>
}

// Beginning of the projects content section
const git_content = () => {
  return <div>
    <p>As a hobby, I like to complete small personal projects that I find interesting.
      Many of my projects can be found on my {GitHub}.</p>
  </div>
}

const this_page_content = () => {
  return <div>
    <p>This webpage has been a great learning experience.
      I fully developed this page myself which allowed me to get some great experience with {React}!</p>
  </div>
}

const get_work_content = (key) => {
  const work_data = About_Json.work[key];
  return {
    title: work_data.title,
    date: work_data.date,
    image: image_map[work_data.image],
    content: (
      <div>
        <ul>
          {work_data.content.map((content_bullet, index) => (
            <li key={index}>{content_bullet}</li>
          ))}
        </ul>
      </div>
    )
  };
};


const edu_blocks = [{title: 'Purdue University: Master of Science', date: '2024 - current', image: purdueP, content: purdue_ms_content() },
                     { title: 'Purdue University: Bachelor of Science', date: '2021 - 2025', image: purdueP, content: purdue_bs_content() },
                     { title: 'Grassfield High School', date: '2017 - 2021', image: grassfieldLogo, content: hs_content() }]
const proj_blocks = [{ title: 'This Page', image: react_logo, content: this_page_content() },
                      { title: 'GitHub', image: gitHub_logo, content: git_content() }]

const work_blocks = Object.keys(About_Json.work).map(k => get_work_content(k))

export { Purdue, edu_blocks, proj_blocks, work_blocks }
