import React, {useEffect, useState} from 'react';
import { ReactTyped } from 'react-typed'
import './Navbar.css'
import Connect from '../components/Connect'
import profileTiny from '../images/profileTiny.png'

const Navbar = () => {
  return (
    <nav className="navbar" >
      <div className="pfp">
        <img src={profileTiny} alt="" />
      </div>
      <div className='name'>
        <h1>Garrett Hurst</h1>
        <strong>
        <ReactTyped
                    strings={[
                      "Computer Engineer",
                      "Cyber Developer",
                      "Data Scientist"
                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  />
        </strong>
      </div>
      <div className="contact">
          <Connect className="contactWidget" />
      </div>
    </nav>
  );
}

export default Navbar;