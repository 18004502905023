import './Home.css'
import { edu_blocks, proj_blocks, work_blocks } from '../static_content/home_content'
import { SectionContainer } from '../components/SectionContainer'


const Home = () => {
  return (
    <div>
      <SectionContainer title='Education' content={edu_blocks}/>
      <SectionContainer title='Work Experience' content={work_blocks}/>
      <SectionContainer title='Projects' content={proj_blocks}/>
    </div>
  );
}

export default Home;